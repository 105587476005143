import { oneLine } from 'common-tags';
import {
  getXPathString,
  getXPathBoolean
} from './xpath';
import { WS_REQUEST_PATH } from '../constants';

type UpdateInfoCustomerParam = {
  ffaSession: string,
  customerId: string,
  newCustomerEmail: string,
  newCustomerPhone?: string
};

export const updateInfoCustomer = async ({
  ffaSession,
  customerId,
  newCustomerEmail,
  newCustomerPhone
} : UpdateInfoCustomerParam) => {
  
  /**
   * updateInfoCustomer XML object
   * is not part of Optitime standard API
   * It is part of a Culligan specific API
   * developped by Optitime (which in turn calls
   * several Optitime APIs)
   */
  const updateInfoCustomerXML = oneLine`
    <updateInfoCustomer
      ffaSession='${ffaSession}' 
      userId='${customerId}'
      mail='${newCustomerEmail}' 
      phone='${newCustomerPhone}'      
    />
  `;

  const response = await fetch(WS_REQUEST_PATH + updateInfoCustomerXML);
  if (!response.ok) {
    // TODO handle error
    throw Error(`Response error code: ${response.statusText}`);
  }      

  const responseText = await response.text();
  const responseXML = (new DOMParser()).parseFromString(responseText, "text/xml");

  const isSuccess = getXPathBoolean(responseXML, "//@status='success'");
  if (!isSuccess) {
    throw Error(getXPathString(responseXML, '//@errorMsg'));
  }
  
  return '';
};